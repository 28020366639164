<template>
  <div class="page">
    <div class="edit_box">
      <div class="item">
        <div :class="['left_box']">
          <span style="color: #ff602a">*</span>社群名称：
        </div>
        <div class="right_box">
          <input
            placeholder="请输入社群名称"
            type="text"
            v-model="form.associationName"
          />
        </div>
      </div>
      <div class="item">
        <div :class="['left_box']">
          <span style="color: #ff602a">*</span>关键词：
        </div>
        <div class="right_box">
          <input
            placeholder="简单描述下社群"
            type="text"
            v-model="form.content"
          />
        </div>
      </div>
      <div class="item">
        <div :class="['left_box', 'disable_box']">
          <span style="color: #ff602a">*</span>社群组织人：
        </div>
        <div class="right_box">
          <input
            disabled
            class="disable_box"
            style="color: rgba(0, 0, 0, 0.85)"
            type="text"
            v-model="userInfo.userName"
          />
        </div>
      </div>
      <div class="bottom_box">
        <van-field
          rows="6"
          style="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
          v-model="form.description"
          type="textarea"
          placeholder="介绍下你的社群吧~"
          maxlength="200"
          show-word-limit
        />
      </div>
      <p class="uploadPicTitle">
        <span style="color: #ff602a">*</span>上传图片
      </p>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.proposalPic"
        :activityPicture.sync="form.proposalPic"
        :maxCount="1"
        ref="load"
      ></v-upload>
      <p class="message">
        请上传您的社群照片，建议上传702px*318px ,单个图片不超过5M。
      </p>
    </div>
    <div class="refuse_btn" @click="confirm()">提交申请</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { userInfoUrl, commitApplyUrl, addAssInfoForMiniUrl } from "./api.js";
export default {
  name: "createCommunity",
  data() {
    return {
      userInfo: {},
      form: {
        proposalPic: "",
        associationName: "",
        content: "",
        description: "",
        directorName: "",
      },
      id: null,
    };
  },
  created() {
    this.getUserInfo();
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  methods: {
    getUserInfo() {
      //获取个人信息
      let params = {
        userId: this.userId,
      };

      this.$axios
        .get(`${userInfoUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
            console.log(this.userInfo);
          }
        });
    },
    confirm() {
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.proposalPic) {
        this.$toast("请上传图片");
        return;
      }
      if (this.form.associationName == "") {
        this.$toast("社群名称不能为空！");
        return;
      }
      if (this.form.description == "") {
        this.$toast("社群介绍不能为空！");
        return;
      }
      if (this.form.content == "") {
        this.$toast("关键词不能为空！");
        return;
      }
      let params = {
        houseId: this.houseId || this.communityId || undefined,
        userId: this.userId,
        userName: this.userInfo.userName,
        tenantId: this.tenantId,
        associationName: this.form.associationName,
        directorName: this.userInfo.userName,
        directorMobile: this.userInfo.mobile,
        content: this.form.content,
        description: this.form.description,
        picture: this.form.proposalPic,
      };
      this.$axios.post(`${addAssInfoForMiniUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast("创建社群成功！");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  box-sizing: border-box;

  .dialog_text {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    text-align: center;
    padding: 86px 124px 58px;
    white-space: nowrap;
  }

  .agree_btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc054 0%, #fe9716 100%);
    border-radius: 10px;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 66px;
    margin: 0 auto;
    margin-top: 34px;
  }

  .refuse_btn {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    background: linear-gradient(180deg, #73a8ff 0%, #307cff 100%);
    text-align: center;
    line-height: 66px;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    margin: 0 auto;
    margin-top: 124px;
  }

  .uploadPicTitle {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 28px;
  }

  .message {
    margin-top: 18px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
  }

  .edit_box {
    width: 100%;
    padding: 0 52px 0 44px;
    box-sizing: border-box;

    .bottom_box {
      margin-top: 32px;
      width: 100%;
      margin-bottom: 38px;
      box-sizing: border-box;
    }

    .item {
      width: 100%;
      height: 106px;
      box-sizing: border-box;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      display: flex;
      line-height: 106px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);

      .left_box {
        font-size: 32px;
        color: #333333;
      }

      .disable_box {
        background-color: #fafafa;
      }

      .right_box {
        flex: 1;
        box-sizing: border-box;

        input {
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          text-align: right;
        }
      }
    }
  }
}
</style>
